import { navigate } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import { isLoggedIn } from '../../services/auth.api';

type Props = {};
const whiteList = [
  { path: '/', preferredHeight: 455 },
  { path: '/howitworks/', preferredHeight: 383 },
  { path: '/ingredients/', preferredHeight: 0 },
  { path: '/pricing/', preferredHeight: 1330 },
  { path: '/ourstory/', preferredHeight: 0 },
  { path: '/sustainability/', preferredHeight: 0 },
  { path: '/blog/', preferredHeight: 0 },
];
function Getstarted({}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [showElement, setShowElement] = useState(false);
  const [path, setPath] = useState('');
  useEffect(() => {
    if (window?.location?.pathname) {
      setPath(window.location.pathname);
    }
    return () => {
      setPath('');
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 599);
    }
    function handleResize() {
      setIsMobile(window.innerWidth <= 599);
    }
    function handleScroll() {
      const findPage = whiteList.find(it => it.path === path);
      if (findPage && findPage.preferredHeight) {
        // console.log({
        //   scroll: window.scrollY,
        //   preferredHeight: findPage.preferredHeight,
        // });
        if (window.scrollY > findPage.preferredHeight) {
          setShowElement(true);
        } else {
          setShowElement(false);
        }
        return;
      }
      if (window.scrollY > window.innerHeight / 2) {
        setShowElement(true);
      } else {
        setShowElement(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [path]);
  const onGetStarted = () => {
    navigate('/getstarted/');
  };
  const getStartedContainer = useMemo(() => {
    return (
      <div className="stickyGetstarted" onClick={onGetStarted}>
        <span>Order Now</span>
      </div>
    );
  }, []);
  // console.log({
  //   path,
  //   condition: whiteList.find(
  //     it => it.path === path || path.search(it.path) >= 0,
  //   ),
  // });
  if (
    !isLoggedIn() &&
    isMobile &&
    showElement &&
    whiteList.find(it => it.path === path || path.search('/blog/') >= 0)
  )
    return getStartedContainer;
  else {
    return <></>;
  }
}

export default Getstarted;
